export const ACCEPT_FILE = {
  'application/pdf': [], // Added for PDF files
  'text/csv': [], // Added for CSV files
  'application/msword': [], // Already present for DOC files
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // Added for DOCX files
  'text/plain': [], // Added for TXT files
  'text/html': [], // Added for HTM and HTML files
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // Added for XLSX files
  'application/vnd.ms-excel': [], // Added for XLS files
  'application/zip': [], // Added for ZIP files
  'application/x-rar-compressed': [], // Added for RAR files
  'application/vnd.rar': [],
  'application/vnd.ms-excel.template': [], // Added for XLT files
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': [], // Added for XLTX files
};

export const RESOURCE_PDF_MAX_SIZE = 10 * Math.pow(1024, 2); // 10MB
export const RESOURCE_FILE = 'file';
