import axios from 'axios';
import Decimal from 'decimal.js';
import { types as sdkTypes, transit } from './sdkLoader';
const axiosInstance = axios.create();

// Application type handlers for JS SDK.
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const handleResponse = res => {
  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader
    ? contentTypeHeader.split(';')[0]
    : null;

  if (res.status >= 400) {
    return res.json().then(data => {
      let e = new Error();
      e = Object.assign(e, data);

      throw e;
    });
  }

  if (contentType === 'application/transit+json') {
    return res.data.then(deserialize);
  } else if (contentType === 'application/json') {
    return res.data;
  }
  return res.data;
};

axiosInstance.interceptors.response.use(response => {
  return handleResponse(response);
});

export const PUT = 'PUT';

const getGeneralOptions = accessToken => {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
  };
};

const put = (path, data, requestHeader = {}) => {
  const headers = {
    ...getGeneralOptions(),
    ...requestHeader,
  };
  const options = {
    url: path,
    method: PUT,
    data: data,
  };
  return axiosInstance({ headers, ...options });
};

export const uploadFileToPresignedUrl = (
  url,
  uploadFile,
  requestHeader = {},
) => {
  return put(url, uploadFile, requestHeader);
};
