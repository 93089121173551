import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconReviewStar from '../../components/IconReviewStar/IconReviewStar';
import { REVIEW_RATINGS } from '../../util/types';

const ReviewRating = props => {
  const { className, rootClassName, reviewStarClassName, rating, viewMode = 'mobile', size = 23 } = props;
  const classes = classNames(rootClassName, className);
  const clampedRating = Math.max(0, Math.min(5, rating));

  return (
    <span className={classes} title={`${rating}/5`} style={{ width: size * 5 }}>
      {REVIEW_RATINGS.map((star, index) => {
        let filled = 0;
        if (clampedRating >= star) {
          filled = 1;
        } else if (clampedRating > star - 1) {
          filled = clampedRating - (star - 1);
        }

        return (
          <IconReviewStar
            key={`star-${viewMode}-${index}`}
            className={reviewStarClassName}
            filled={filled}
            size={size}
            viewMode={viewMode}
          />
        );
      })}
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
  size: 23,
  viewMode: 'mobile',
};

const { string, number } = PropTypes;

ReviewRating.propTypes = {
  rating: number.isRequired,
  reviewStarClassName: string,
  rootClassName: string,
  className: string,
  size: number,
  viewMode: string,
};

export default ReviewRating;
